import request from "tools/request.js"

//建筑分类 添加建筑分类
class hetong_add_post {
	constructor(parent) {
		this.parent = parent
	}

	m_main() {
		const that = this;



		let session = localStorage.getItem("session");



		let params = {
			m_hetong: that.parent.m_hetong, 
			m_ding_id: that.parent.m_ding_id, 
			session: session
		}

		request.post('/dingdan/hetong_add_post', params).then(res => {
			if (res.data.state == 200) {
				this.parent.$notify({
					title: '成功',
					message: res.data.message,
					type: 'success'
				});
			} else {
				this.parent.$notify({
					title: '失败',
					message: res.data.message,
					type: 'error'
				});
			}
			that.parent.parent.drawer_3 = false;
			that.parent.parent.shangjia_get.m_main();
		})

	}
}

export default hetong_add_post