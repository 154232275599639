import request from "tools/request.js"

//活动设置
class dingdan_wuliu_post {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		 
		  
		let m_ding_id 			= that.parent.m_ding_id; 
		let m_wuliu_xinxi 	    = that.parent.m_wuliu_xinxi; 
		let session 		    = localStorage.getItem("session"); 
		
	 
		let params = {  
			m_ding_id : m_ding_id,
			m_wuliu_xinxi : m_wuliu_xinxi, 
			session : session
		}  
		
		request.post('/dingdan/dingdan_wuliu_post',params).then(res => { 
			if(res.data.state == 200){
				this.parent.$notify({
				  title: '成功',
				  message: res.data.message,
				  type: 'success'
				}); 
			}else{
				this.parent.$notify({
				  title: '失败',
				  message: res.data.message, 
				  type: 'error'
				}); 
			}
		 
			that.parent.dingdan_wuliu_get.m_main();
		})
		 
	}
}

export default dingdan_wuliu_post