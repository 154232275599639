import request from "tools/request.js"

class dingdan_baojia_get {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		
		  
		let m_ding_id = that.parent.m_ding_id;  
		let session = localStorage.getItem("session");
		
		let params = { 
			m_ding_id : m_ding_id, 
			session : session
		}  
		
		request.post('/dingdan/dingdan_info_get',params).then(res => {
			
			 
			that.parent.m_baojia_info = res.data.m_dingdan_info;  
			
			 
			console.log(that.parent.m_baojia_info );
		})
		 
	}
}

export default dingdan_baojia_get