<template>
	<div>
		<layout>

			<br />
			<el-row :gutter="20">

				<el-col :span="8">
					<el-col :span="8"><label class="yu_input_label">订单id:</label>
					</el-col>
					<el-col :span="16">
						<el-input v-model="ding_id" @input="m_input_change" placeholder="请输入内容" clearable>
						</el-input>
					</el-col>
				</el-col>
				<el-col :span="8">
					<el-col :span="8"><label class="yu_input_label">会员id:</label>
					</el-col>
					<el-col :span="16">
						<el-input v-model="huiyuan_id" @input="m_input_change" placeholder="请输入内容" clearable>
						</el-input>
					</el-col>
				</el-col>

			</el-row>
			<br />


			<br />
			<el-table :data="m_dingdan_list" style="width: 100%">
				<el-table-column prop="ding_id" label="id">
				</el-table-column>
				<el-table-column prop="ding_user_id" label="用户id">
				</el-table-column>
				<el-table-column prop="ding_jine" label="订单金额">
				</el-table-column>
				<el-table-column prop="ding_time" label="下单时间">
				</el-table-column>
				<el-table-column prop="ding_state" label="订单状态">
				</el-table-column>

				<el-table-column label="操作" show-overflow-tooltip align="right" width="380px">
					<template slot-scope="scope">
						<el-button size="mini" type="default" @click="f_baojia_info(scope.row.ding_id )">报价</el-button>
						<el-button size="mini" type="default" @click="f_hetong_add(scope.row.ding_id )">上传合同</el-button>

						<el-button size="mini" type="default"
							@click="f_dingdan_info(scope.row.ding_id )">订单详情</el-button>
						<!-- <el-button size="mini" type="default" @click="f_wuliu_info(scope.row.ding_id )">物流信息</el-button>
						  -->
					</template>
				</el-table-column>
			</el-table>

			<br />
			<br />

			<div class="yu_content_right">
				<el-pagination background @current-change="page_current_change" layout="prev, pager, next"
					:page-size="page_size" :total="max_page">
				</el-pagination>
			</div>
			<br />
		</layout>

		<el-drawer title="添加订单" :visible.sync="drawer_1" size="600px">
			<dingdan_add :parent="me"></dingdan_add>
		</el-drawer>

		<el-drawer title="订单报价" :visible.sync="drawer_2" size="600px">
			<dingdan_baojia :key="m_ding_id" :m_ding_id="m_ding_id" :parent="me"></dingdan_baojia>
		</el-drawer>
		<el-drawer title="上传合同" :visible.sync="drawer_3" size="600px">
			<hetong_add :key="m_ding_id" :m_ding_id="m_ding_id" :parent="me"></hetong_add>
		</el-drawer>
		<el-drawer title="订单详情" :visible.sync="drawer_4" size="600px">
			<dingdan_info :key="m_ding_id" :m_ding_id="m_ding_id" :parent="me"></dingdan_info>
		</el-drawer>
		<el-drawer title="订单物流" :visible.sync="drawer_5" size="600px">
			<dingdan_wuliu :key="m_ding_id" :m_ding_id="m_ding_id" :parent="me"></dingdan_wuliu>
		</el-drawer>
		<audio id='audioPlay' src="http://106.75.13.203:4035/1.mp3" hidden='true'></audio>
	 
	</div>
</template>

<script>
	import layout from 'views/layout.vue'

	import dingdan_info from './components/dingdan_info/dingdan_info'
	import dingdan_baojia from './components/dingdan_baojia/dingdan_baojia'
	import hetong_add from './components/hetong_add/hetong_add'
	import dingdan_wuliu from './components/dingdan_wuliu/dingdan_wuliu'

	import dingdan_get from "./expand/dingdan_get.js"
	import dingdan_xiang_get from "./expand/dingdan_xiang_get.js"
	export default {
		components: {
			layout,
			dingdan_info,
			dingdan_baojia,
			hetong_add,
			dingdan_wuliu
		},
		data() {
			return {
				me: this,
				ding_id: "",
				huiyuan_id: "",
				m_ding_id: "",
				m_dingdan_list: "",
				drawer_1: false,
				drawer_2: false,
				drawer_3: false,
				drawer_4: false,
				drawer_5: false,
				checkedNames: [],
				page: 1,
				page_size: 10,
				max_page: 10,
				m_yiyu_id: 0
			}
		},
		created() {
			const that = this;
			
			
			  // const innerAudioContext = uni.createInnerAudioContext();
			  //     innerAudioContext.autoplay = true;
			  //     innerAudioContext.src = 'http://106.75.13.203:4035/1.mp3';
			   // 创建一个新的Audio对象
			 // const audioContext = new AudioContext();
			 // const element = document.querySelector(audio);
			 // const source = audioContext.createMediaElementSource(element);
			 // source.connect(audioContext.destination)
			 // audio.play();
			   // const music = new Audio('http://106.75.13.203:4035/1.mp3');
			   // music.play();
			   
			 
			        
			           
					   
			that.dingdan_get = new dingdan_get(that);
			that.dingdan_get.m_main();
			that.dingdan_xiang_get = new dingdan_xiang_get(that);
			
			setInterval(function(){
				 
				that.dingdan_xiang_get.m_main();
			},2000);
		},
		methods: {

			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			page_current_change(val) {
				//翻页
				const that = this;

				that.page = val;
				that.dingdan_get.m_main();

				document.documentElement.scrollTop = document.body.scrollTop = 0;
			},
			m_input_change(val) {
				const that = this;

				that.dingdan_get.m_main();
			},
			f_baojia_info(ding_id) {
				const that = this;

				that.m_ding_id = ding_id;
				console.log("youyou0");

				console.log(ding_id);
				that.drawer_2 = true;
			},
			f_xiangqing_info(ding_id) {
				const that = this;

				that.m_ding_id = ding_id;
				that.drawer_1 = true;
			},
			f_hetong_add(ding_id) {
				const that = this;
				that.m_ding_id = ding_id;
				that.drawer_3 = true;
			},
			f_dingdan_info(ding_id) {
				const that = this;
				that.m_ding_id = ding_id;
				that.drawer_4 = true;
			},
			f_wuliu_info(ding_id) {
				const that = this;
				that.m_ding_id = ding_id;
				that.drawer_5 = true;
			}


		}
	}
</script>