<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>


			<br />
			<el-row>
				<el-col class="yu_input_label" :span="4">
					订单id:
				</el-col>
				<el-col :span="20">
					<el-input v-model="m_ding_id" disabled></el-input>
				</el-col>
			</el-row>
			<br />

			<div v-for="(item,index) in m_baojia_info">
				<el-row>
					<el-col class="yu_input_label" :span="4">
						商品:
					</el-col>
					<el-col :span="10">
						<el-input v-model='item.diit_id +" "+ item.shpi_xinghao+" *"+ item.diit_no'></el-input>
					</el-col>
					<el-col class="yu_input_label" :span="2">
						<p>&nbsp;&nbsp;</p>
					</el-col>
					<el-col class="yu_input_label" :span="4">
						单价:
					</el-col>
					<el-col :span="4">
						<el-input v-model="item.diit_jiage" @input="my_danjia_input"></el-input>
					</el-col>
				</el-row>
				<br />
			</div>

			<el-row>
				<el-col class="yu_input_label" :span="4">
					订单总价:
				</el-col>
				<el-col :span="20">
					<el-input v-model="m_zongjia" disabled></el-input>
				</el-col>
			</el-row>
			<br />
			

			<!-- <el-row>
				<el-col class="yu_input_label" :span="8">
					门票价格:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_dingdan_info.hudo_menpiao"></el-input>
				</el-col>
			</el-row>
			<br /> -->


			<br />
			<el-row class="yu_content_right">
				<el-button @click="dingdan_baojia_set" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import dingdan_baojia_get from "./expand/dingdan_baojia_get.js";
	import dingdan_baojia_post from "./expand/dingdan_baojia_post.js";

	export default {

		props: ["m_ding_id", "parent"],
		data() {
			return {
				m_baojia_info: [],
				m_zongjia : 0

			}
		},
		created() {
			const that = this;

			console.log("3333");
			console.log(that.m_ding_id);

			that.dingdan_baojia_get = new dingdan_baojia_get(that);
			that.dingdan_baojia_get.m_main();
		},
		methods: {
			dingdan_baojia_set() {
				const that = this;
				that.dingdan_baojia_post = new dingdan_baojia_post(that);
				that.dingdan_baojia_post.m_main();
			},
			my_danjia_input(event){
				const that = this;
				console.log(event); 
				// this.inputValue = event.target.value;
				
				// return 0;
				that.m_zongjia = 0;
				for (let i = 0;i <that.m_baojia_info.length;i++) {
					that.m_zongjia +=  that.m_baojia_info[i]["diit_no"] * parseFloat(that.m_baojia_info[i]["diit_jiage"])
				}
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>