import request from "tools/request.js"

class dingdan_baojia_get {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		
		  
		let m_ding_id = that.parent.m_ding_id;  
		let session = localStorage.getItem("session");
		
		let params = { 
			m_ding_id : m_ding_id, 
			session : session
		}  
		
		request.post('/dingdan/dingdan_baojia_get',params).then(res => {
			
			 
			that.parent.m_baojia_info = res.data.result;  
			
			that.parent.m_zongjia = 0;
			for (let i = 0;i <that.parent.m_baojia_info.length;i++) {
				that.parent.m_zongjia += that.parent.m_baojia_info[i]["diit_no"] * that.parent.m_baojia_info[i]["diit_jiage"]
			}
			console.log(that.parent.m_baojia_info );
		})
		 
	}
}

export default dingdan_baojia_get