import request from "tools/request.js"

//活动设置
class dingdan_info_post {
	constructor(parent) {
	    this.parent = parent
	}
	
	m_main() {
		const that = this;
		 
		  
		let m_ding_id 			= that.parent.m_ding_id; 
		let m_baojia_info 	    = that.parent.m_baojia_info; 
		let session 		    = localStorage.getItem("session"); 
		
	 
		let params = {  
			m_ding_id : m_ding_id,
			m_baojia_info : m_baojia_info, 
			session : session
		}  
		
		request.post('/dingdan/dingdan_info_post',params).then(res => { 
			if(res.data.state == 200){
				this.parent.$notify({
				  title: '成功',
				  message: res.data.message,
				  type: 'success'
				}); 
			}else{
				this.parent.$notify({
				  title: '失败',
				  message: res.data.message, 
				  type: 'error'
				}); 
			}
			that.parent.parent.drawer_4 = false; 
			that.parent.parent.dingdan_get.m_main(); 
		})
		 
	}
}

export default dingdan_info_post