<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>


			<br />
			<el-row>
				<el-col class="yu_input_label" :span="4">
					订单id:
				</el-col>
				<el-col :span="20">
					<el-input v-model="m_ding_id" disabled></el-input>
				</el-col>
			</el-row>
			<br />

			 
			<el-row>
				<el-col class="yu_input_label" :span="4">
					添加信息:
				</el-col>
				<el-col :span="20">
					<el-input v-model="m_wuliu_xinxi"></el-input>
				</el-col>
			</el-row>
			<br />
			 
			<el-row class="yu_content_right">
				<el-button @click="my_dingdan_wuliu_post" plain>点击提交</el-button>
			</el-row>
			 
			
			<el-row>
				<el-col class="yu_input_label" :span="4">
					历史信息:
				</el-col>
				<el-col :span="20">
					<div class="yu_input_label" v-for="(item,index) in m_dingdan_wuliu">
						{{item.diwu_time}} - {{item.diwu_text}} 
					</div>
					
				</el-col>
			</el-row>
			<br />
			 
			 
		</div>
	</div>
</template>

<script>
	import dingdan_wuliu_get from "./expand/dingdan_wuliu_get.js";
	import dingdan_wuliu_post from "./expand/dingdan_wuliu_post.js";

	export default {

		props: ["m_ding_id", "parent"],
		data() {
			return {
				m_dingdan_wuliu: [],
				m_wuliu_xinxi : "" 
			}
		},
		created() {
			const that = this;

			console.log("3333");
			console.log(that.m_ding_id);

			that.dingdan_wuliu_get = new dingdan_wuliu_get(that);
			that.dingdan_wuliu_get.m_main();
		},
		methods: {
			my_dingdan_wuliu_post() {
				const that = this;
				that.dingdan_wuliu_post = new dingdan_wuliu_post(that);
				that.dingdan_wuliu_post.m_main();
			},
			my_danjia_input(event){
				const that = this;
				console.log(event); 
				// this.inputValue = event.target.value;
				
				// return 0;
				that.m_zongjia = 0;
				for (let i = 0;i <that.m_baojia_info.length;i++) {
					that.m_zongjia +=  that.m_baojia_info[i]["diit_no"] * parseFloat(that.m_baojia_info[i]["diit_jiage"])
				}
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>