<!-- 还没到全 入库 -->
<template>
	<div class="yu_box_1">
		<div>
			<br /> 
			<el-row>
				<el-col class="yu_input_label" :span="8">
					订单id:
				</el-col>
				<el-col :span="16">
					<el-input v-model="m_ding_id" disabled></el-input>
				</el-col>
			</el-row>
			<br />
			 
			<el-row>
				<el-col class="yu_input_label" :span="8">
					合同:
				</el-col>
				<el-col :span="16">
					<el-upload class="avatar-uploader" action="http://106.75.13.203:4035/upload" :show-file-list="false"
						:on-success="handleAvatarSuccess"  >
						<!-- <img v-if="m_img" :src="m_img" class="avatar"> -->
						<el-input v-if="m_hetong"  v-model="m_ok_text" >上传成功</el-input>
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</el-col>
			</el-row> 
			 
			<br />
			<el-row class="yu_content_right">
				<el-button @click="hetong_add" plain>点击提交</el-button>
			</el-row>
		</div>
	</div>
</template>

<script>
	import hetong_add_post from "./expand/hetong_add_post.js";

	export default {

		props: ["m_ding_id","parent"],
		data() {
			return {
				m_ok_text: "上传成功",
				m_hetong :"", 
			}
		},
		created() {
			const that = this;
			// that.shoppe_add_info_get = new shoppe_add_info_get(that);
			// that.shoppe_add_info_get.m_main();
		},
		methods: {
			hetong_add() {
				const that = this;
				that.hetong_add_post = new hetong_add_post(that);
				that.hetong_add_post.m_main();
			},
			handleAvatarSuccess(res, file) {
				const that = this;
				console.log("dd");
				console.log(res.upload_file);

				that.m_hetong = res.img_val;
				// this.m_img = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				// const isLt2M = file.size / 1024 / 1024 < 2;

				// if (!isJPG) {
				// 	this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				// if (!isLt2M) {
				// 	this.$message.error('上传头像图片大小不能超过 2MB!');
				// }
				return isJPG && isLt2M;
			}
		}
	}
</script>


<style>
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>
